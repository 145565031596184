@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.grow {
  &__container {
    .pageContainer();
  }

  &__page-container {
    padding: 0 ~'min(8vw, 80px)';
  }

  &__hero {
    h2 {
      font-size: 48px;
      font-weight: bold;

      @media @mobile {
        font-size: 32px;
      }
    }
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;

    img {
      height: 80px;
    }
  }

  &__heading {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
  }

  &__points {
    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: start;
      gap: 50px;

      @media @mobile {
        grid-template-columns: 1fr;
      }
    }

    &-header {
      color: #8c52ff;
      font-size: 38px;
    }

    &-body {
      font-size: 18px;
    }
  }

  &__example {
    display: grid;
    grid-template-areas: 'title title' 'body image';
    gap: 40px;

    @media @mobile {
      grid-template-areas: 'title' 'image' 'body';
    }

    &-title {
      color: #8c52ff;
      font-size: 28px;
      font-weight: bold;
      grid-area: title;
    }

    &-image {
      grid-area: image;
    }

    &-body {
      font-size: 18px;
      grid-area: body;

      ul {
        list-style: square;
        padding-left: 1em;
      }
    }

    &-split {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      gap: 20px;
      text-align: center;

      > div {
        flex: 0 0 300px;
      }
    }

    &-stat {
      color: #8c52ff;
    }
  }

  &__small {
    font-size: 0.85em;
  }

  &__images {
    display: flex;
    flex-flow: column;
    gap: 50px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__link {
    &-text {
      display: flex;
      align-items: center;
      justify-content: center;

      color: #8629df;
    }

    &-arrow {
      width: auto;
      height: 30px;
      margin: 0 20px 0 0;
    }
  }

  &__map {
    display: block;
  }

  &__mail {
    text-align: center;
    font-size: 48px;
  }

  &__mail-link {
    text-decoration: underline;
  }

  &__cards {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin: 0 30px;
  }

  &__card {
    color: #000;
    background: #ffffff;
    padding: 30px;
    font-size: 0.9em;

    &-number {
      font-weight: 900;
      font-size: 50px;
      color: #dedede;
      line-height: 1;
      margin: -20px 20px 20px -10px;
    }

    h3 {
      font-size: 36px;
      margin-bottom: 0.5em;
    }
  }

  &__people {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 50px;
  }

  &__person {
    display: flex;
    flex-flow: column;
    gap: 20px;
    text-align: center;

    &-image {
      img {
        height: 150px;
      }
    }

    &-body {
      font-size: 18px;
    }

    &-link {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__clocks {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    .button();

    @media @mobile {
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: flex-start;
      padding-left: 40px;
      padding-right: 40px;
    }

    .clock {
      flex: none;
    }

    .clock:nth-child(2) {
      margin: 0 55px;

      @media @tablet {
        margin: 0 38px;
      }

      @media @tiny {
        margin: 0 38px;
      }
    }
  }
}
