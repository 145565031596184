@import '../../common/styles/responsive';

.light {
  background-color: @backgroundLight;
  color: #000;
}

.dark {
  background-color: @backgroundDark;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.faded {
  opacity: 0.5;
}

.indent {
  text-indent: 1em;
}

.desktop-only {
  .desktopOnly();
}

.mobile-only {
  .mobileOnly();
}

.center {
  text-align: center;
}
